import { parseJSON } from 'date-fns';
import { format } from 'date-fns-tz';

export const formatPhone = (phoneNumber: string) => {
  if (phoneNumber.length == 11) return phoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1($2)$3-$4');
  else if (phoneNumber.length == 10) return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3');
  return phoneNumber;
}

export const formatDate = (date: string | number | Date): string => {
  return format(parseJSON(date), 'LL/dd/yyyy');
}

export const formatDateTime = (date: string | number | Date): string => {
  return format(parseJSON(date), 'LL/dd/yyyy HH:mm zzz');
}

export const formatDateTimeWindow = (date: string | number | Date, date2: string | number | Date | null | undefined) => {
  if (!date2) {
      return formatDateTime(date);
  }

  const scheduled = parseJSON(date);
  const latest = parseJSON(date2);

  // Same Date/Time
  if (format(scheduled, 'LL/dd/yyyy HH:mm zzz') === format(latest, 'LL/dd/yyyy HH:mm zzz')) {
    return format(scheduled, 'LL/dd/yyyy HH:mm zzz')
  }

  // Same Date
  if (format(scheduled, 'LL/dd/yyyy') === format(latest, 'LL/dd/yyyy')) {
    return `${format(scheduled, 'LL/dd/yyyy HH:mm')} - ${format(latest, 'HH:mm zzz')}`;
  }

  // Difference Date
  return `${format(scheduled, 'LL/dd/yyyy HH:mm')} - ${format(latest, 'LL/dd/yyyy HH:mm zzz')}`;
}