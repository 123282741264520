import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { ReactNode, useState } from 'react';

export type useConfirmProps = {
    title?: string;
    message?: ReactNode;
    labels?: {
        confirm: string;
        cancel: string;
    };
    themeColor?: "primary" | "success" | "warning" | "error";
};

const useConfirm = ({
    title = "Please confirm",
    message = "Are you sure?",
    labels = {
        confirm: "OK",
        cancel: "Cancel",
    },
    themeColor = "primary",
    }: useConfirmProps) => {
    const [promise, setPromise] = useState<{
        resolve: (value: unknown) => void;
    } | null>(null);
    const [_title, setTitle] = useState(title);
    const [_message, setMessage] = useState(message);

    const confirm = (message?: string, title?: string) => new Promise((resolve) => {
        setMessage(message ?? _message);
        setTitle(title ?? _title);
        setPromise({ resolve });
    });

    const handleClose = () => {
        setPromise(null);
    };

    const handleConfirm = () => {
        promise?.resolve(true);
        handleClose();
    };

    const handleCancel = () => {
        promise?.resolve(false);
        handleClose();
    };

    const ConfirmationDialog = () => 
        <Dialog open={promise !== null} onClose={handleCancel} maxWidth="xs" fullWidth>
            <DialogTitle>{_title}</DialogTitle>
            <p style={{ margin: '25px', textAlign: 'center' }}>
                <span style={{ fontSize: 'larger' }}>{_message}</span>
            </p>
            <DialogActions>
                <Button onClick={handleCancel}>
                    {labels.cancel}
                </Button>
                <Button color={themeColor} onClick={handleConfirm} autoFocus>
                    {labels.confirm}
                </Button>
            </DialogActions>
        </Dialog>;

    return {
        ConfirmationDialog,
        confirm
    };
};

export default useConfirm;