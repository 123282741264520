import { GridCellProps } from '@progress/kendo-react-grid';
import * as React from 'react';
import { ViewModel } from '.';
import { ILink } from 'core/link';
import AppTracking from './AppTracking';

const AppTrackingcell = (props: GridCellProps) => {
  const [trackingLink, setTrackingLink] = React.useState<ILink | null>(null);

  const data = props.dataItem as ViewModel;
  return (
    <td>
      {trackingLink && <AppTracking
        CloseDialog={() => setTrackingLink(null)}
        Link={trackingLink} />}
      {data.Links.find((x: ILink) => x.Name === 'AppTrackingSetup') && <a
      href="#"
      className="track-app-setup"
      onClick={(e) => {
        setTrackingLink(data.Links.find((x: ILink) => x.Name === 'AppTrackingSetup') as ILink);
        e.preventDefault();
      }}
    >
      Track By App
    </a>}
    {data.Links.find((x: ILink) => x.Name === 'AppTracking') && <a
      href="#"
      className="track-app-tracked"
      onClick={(e) => {
        setTrackingLink(data.Links.find((x: ILink) => x.Name === 'AppTracking') as ILink);
        e.preventDefault();
      }}
    >
      App Tracked
    </a>}
    {data.Links.find((x: ILink) => x.Name === 'AppTrackingStale') && <a
      href="#"
      className="track-app-stale"
      onClick={(e) => {
        setTrackingLink(data.Links.find((x: ILink) => x.Name === 'AppTrackingStale') as ILink);
        e.preventDefault();
      }}
    >
      App &gt;{data.DriverAppCarrierHours} Hrs
    </a>}
    {data.Links.find((x: ILink) => x.Name === 'AppTrackingRequested') && <a
      href="#"
      className="track-app-requested"
      onClick={(e) => {
        setTrackingLink(data.Links.find((x: ILink) => x.Name === 'AppTrackingRequested') as ILink);
        e.preventDefault();
      }}
    >
      App Requested
    </a>}
  </td>
  );
}

export default React.memo(AppTrackingcell, (prev, next) => prev.dataItem.Hash === next.dataItem.Hash);