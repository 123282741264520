import { GridCellProps } from '@progress/kendo-react-grid';
import { formatNumber } from '@progress/kendo-intl';

import * as React from 'react';
import { ViewModel } from '.';

const PayCell = (props: GridCellProps) => {
  const data = props.dataItem as ViewModel;
  return (
    <td style={{ textAlign: 'right' }}>
      {formatNumber(data.TotalPay, 'C')}
    </td>
  );
}
export default React.memo(PayCell, (prev, next) => prev.dataItem.Hash === next.dataItem.Hash);