import { classNames } from '@progress/kendo-react-common';
import { GridColumnMenuWrapper, GridHeaderCellProps } from "@progress/kendo-react-grid";

const JustifyEndHeaderCell = (props: GridHeaderCellProps) => {
  const { columnMenuWrapperProps } = props;
  const clickProps = props.onClick ? {
    onClick: props.onClick
  } : {};
  return <span className="k-cell-inner">
    <span className={classNames('k-link', { '!k-cursor-default': !props.columnMenuWrapperProps.sortable })} {...clickProps} style={{ justifyContent: 'end' }}>
      <span className="k-column-title">{props.title || props.field || '\u00A0'}</span>
      {props.children}
    </span>
    {columnMenuWrapperProps.columnMenu && <GridColumnMenuWrapper {...columnMenuWrapperProps} />}
  </span>;
}

export default JustifyEndHeaderCell;