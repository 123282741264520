import { useEffect, useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { fetchApi } from "core/fetchApi";
import { parseJSON } from "date-fns";
import SingleLocationAutoComplete from "components/SingleLocationAutoComplete";

export type NewPositionType = {
  DateTime: Date;
  City: string;
  State: string;
  ZipCode: string;
}

type Props = {
  close: () => void;
  save: (data: NewPositionType) => Promise<void>;
}

const ManualPositionPopup = ({ close, save }: Props) => {

  const [saving, setSaving] = useState(false);
  const [newPosition, setNewPosition] = useState<NewPositionType>({ DateTime: new Date(), City: '', State: '', ZipCode: '' });

  const savePosition = (data: NewPositionType) => {
    setSaving(true);
    save(data)
      .finally(() => setSaving(false));
  }

  useEffect(() => {
    // Get Current Server Time
    fetchApi<string>({ url: 'DateTimeUtcNow' })
      .then((datetime: string) => {
        setNewPosition(prev => ({
          ...prev,
          DateTime: parseJSON(datetime)
        }));
      });
  }, []);

  return <Dialog
    title="Manual Position Update"
    className="dialog-w800"
    onClose={close}
  >
    <div className="k-form k-form-md">
      <DateTimePicker
        required
        format="MM/dd/yyyy HH:mm"
        formatPlaceholder="formatPattern"
        value={newPosition.DateTime}
        onChange={(e) => {
          if (e.value) setNewPosition({ ...newPosition, DateTime: e.value });
        }}
      />
      <br /><br />
      <SingleLocationAutoComplete
        value=""
        autoCompleteProps={{
          placeholder: 'Enter City, State or Zip',
        }}
        onSelected={(location) => {
          if (location && location.Address) {
            setNewPosition({
              ...newPosition,
              City: location.Address.City,
              State: location.Address.State,
              ZipCode: location.Address.Zip
            });
          } else {
            setNewPosition({
              ...newPosition,
              City: '',
              State: '',
              ZipCode: ''
            });
          
          }
        }}
      />
    </div>
    <DialogActionsBar>
      <Button onClick={close}>Close</Button>
      <Button themeColor="primary" disabled={saving} onClick={() =>savePosition(newPosition)}>Save Position</Button>
    </DialogActionsBar>
  </Dialog>
}

export default ManualPositionPopup;