
import { AppBar as MuiAppBar, AppBarProps as MuiAppBarProps, Box, Container, Divider, Drawer as MuiDrawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Typography, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { memo, useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import * as Sentry from '@sentry/react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import HistoryIcon from '@mui/icons-material/History';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WarningIcon from '@mui/icons-material/Warning';
import BarChartIcon from '@mui/icons-material/BarChart';
import LoadBoardProvider from 'contexts/loadboard/provider';
import { useAuthDispatch, useAuthState } from 'contexts/auth/context';
import { fetchApi } from 'core/fetchApi';
import HeadwayWidget from './HeadwayWidget';

const styles = {
  title: {
    flexGrow: 1,
  },
};

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBarStyled = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  borderBottom: '2px solid #C5382C',
  backgroundImage: 'linear-gradient(to right,white,#175985,#175985)',
  color: 'black',
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const Layout = () => {
  const route = useLocation();
  const [title, setTitle] = useState('');
  const [carrierName, setCarrierName] = useState('');
  const { email } = useAuthState();
  const { signOut } = useAuthDispatch();

  // For Drawer
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  // For Right Menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (carrierName) return;
    fetchApi<{ CarrierName: string }>({ url: "Info" })
      .then(({ CarrierName }) => {
        setCarrierName(CarrierName);
      })
  }, [carrierName, setCarrierName]);

  useEffect(() => {
    switch(route.pathname)
    {
      case "/loadboard":
        setTitle("Load Board");
        break;
      case "/loadboard/notifications":
        setTitle("Posted Load Emails");
        break;
      case "/bidhistory":
        setTitle("Bid History");
        break;
      case "/track":
        setTitle("Track");
        break;
      case "/performance":
        setTitle("Performance");
        break;
      case "/settings":
        setTitle("Settings");
        break;
      default:
        setTitle("");
    }
  }, [route.pathname]);

  const emailSupport = () => {

  // Create Sentry Issue
  Sentry.withScope((scope) => {
    scope.setLevel('debug');

    const subject = `Support Request - ${carrierName}`;
    const sentryId = Sentry.captureMessage(subject);
    const sentryUrl = `https://sentry.io/issues/?project=4506717405708288&query=id%3A${sentryId}`;
    const body = `Please attach any screen shots if possible and indicate any and all identifiers below that apply to this issue that would help us determine the cause:

Quote No: 
Other Identifier: 
Nature of Problem: 
When did problem start occurring: 
Other Relevant Info: 

--------------------

The link below is used to assist tech. support with your issue.  Please do not remove it from this email.
${sentryUrl}`;
      window.location.href = `mailto:app@load1.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    });
  }

  return <>
    <AppBarStyled position="absolute" open={drawerOpen}>
      <Toolbar
        sx={{
          pr: '24px', // keep right padding when drawer closed
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          edge="start"
          sx={{
            marginRight: 1,
            ...(drawerOpen && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box style={{ paddingRight: 10 }}>
          <Link to="/">
            <img
              height="32"
              src={`/images/logo.png`}
              alt='Load One Logo'
            />
          </Link>
        </Box>
        <Typography variant="h6" style={styles.title}>
          {title || "Carrier"}
        </Typography>
        <div>
          <HeadwayWidget />
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircleIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={menuOpen}
            onClose={handleClose}
          >
            <MenuItem disabled>{carrierName}</MenuItem>
            <MenuItem disabled>{email}</MenuItem>
            <Divider />
            <MenuItem component={Link} to="/loadboard/notifications">Posted Load Emails</MenuItem>
            <MenuItem component={Link} to="/settings">Settings</MenuItem>
            <MenuItem onClick={emailSupport}>Email Support</MenuItem>
            <Divider />
            <MenuItem onClick={signOut}>Sign Out</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBarStyled>
    <Drawer variant="permanent" open={drawerOpen}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={toggleDrawer}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        <ListItemButton selected={route.pathname === "/loadboard"} component={Link} to="/loadboard">
          <Tooltip title="Load Board">
            <ListItemIcon>
              <RequestQuoteIcon />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Load Board" />
        </ListItemButton>
        <ListItemButton selected={route.pathname === "/bidhistory"} component={Link} to="/bidhistory">
          <Tooltip title="Bid History">
            <ListItemIcon>
              <HistoryIcon />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Bid History" />
        </ListItemButton>
        <ListItemButton selected={route.pathname === "/track"} component={Link} to="/track">
          <Tooltip title="Track">
            <ListItemIcon>
              <LocalShippingIcon />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Track" />
        </ListItemButton>
        <ListItemButton selected={route.pathname === "/exceptions"} component={Link} to="/exceptions">
          <Tooltip title="Service Exceptions">
            <ListItemIcon>
              <WarningIcon />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Service Exceptions" />
        </ListItemButton>
        <ListItemButton selected={route.pathname === "/performance"} component={Link} to="/performance">
          <Tooltip title="Performance">
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Performance" />
        </ListItemButton>
      </List>
    </Drawer>
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Toolbar />
      <Container maxWidth={false} sx={{ mt: 4 }}>
        <LoadBoardProvider>
          <Outlet />
        </LoadBoardProvider>
      </Container>
    </Box>
  </>;
}

export default memo(Layout);