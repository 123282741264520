
import { GridCellProps } from '@progress/kendo-react-grid';
import { formatNumber } from '@progress/kendo-intl';
import * as React from 'react';
import { parseJSON } from 'date-fns';
import { format } from 'date-fns-tz';
import { ViewModel } from '.';

const ETACell = (props: GridCellProps) => {
  const data = props.dataItem as ViewModel;
  return (
    <td>
      {data.ETADateTime && format(parseJSON(data.ETADateTime), 'LL/dd/yyyy HH:mm zzz')}<br />
      {data.MinutesLate > 0 && <span style={{ color: 'red' }}>Late: {formatNumber(data.MinutesLate,'n0')} min.<br /></span>}
    </td>
  );
}
export default React.memo(ETACell, (prev, next) => prev.dataItem.Hash === next.dataItem.Hash);