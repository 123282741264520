import { GridCellProps } from '@progress/kendo-react-grid';
import { formatNumber } from '@progress/kendo-intl';
import * as React from 'react';
import { ViewModel } from '.';

const DistanceCell = (props: GridCellProps) => {
  const data = props.dataItem as ViewModel;
  return (
    <td style={{ textAlign: 'right' }}>
      {formatNumber(data.LoadedMiles, 'n0')}<br />
      Out: {formatNumber(data.ETAMiles, 'n0')}
    </td>
  );
}
export default React.memo(DistanceCell, (prev, next) => prev.dataItem.Hash === next.dataItem.Hash);