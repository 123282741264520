import { useCallback, useEffect, useState } from 'react';
import { Chip, Tooltip } from '@mui/material';
import { DataSourceRequestState, toDataSourceRequestString } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridCellProps, GridColumn as Column, GridColumnMenuFilter, GridColumnProps, GridDataStateChangeEvent, GridExpandChangeEvent, GridNoRecords, GridToolbar } from '@progress/kendo-react-grid';
import { formatNumber } from '@progress/kendo-intl';
import StarIcon from '@mui/icons-material/Star';
import CheckIcon from '@mui/icons-material/Check';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DateTimeCell from 'components/DateTimeCell';
import { fetchApi } from 'core/fetchApi';
import ColumnMenu from './ColumnMenu';
import LoadBoardDetailRow from './DetailRow';

enum QuoteOfferStatus {
  Offered,
  Declined,
  Accepted,
  Timeout,
  Rejected,
  Withdrawn,
  Retracted,
}

type ViewModel = {
  QuoteOfferID: number;
  QuoteID: number;
  CarrierID: number;
  CarrierContactID: number | null;
  PickupCity: string;
  PickupState: string;
  PickupDateTime: Date;
  PeliveryCity: string;
  PeliveryState: string;
  PeliveryDateTime: Date;
  TotalPieces: number;
  TotalWeight: number;
  VehicleType: string;
  Distance: number;
  OfferDateTime: Date;
  ResponseDateTime?: Date;
  AllInRate: number;
  Confirmed: boolean;
  Awarded: boolean;
  CarrierVehicleNumber: string;
  EmailAddress: string;
  Status: QuoteOfferStatus;

  expanded?: boolean;
}

const BidHistory = () => {
  const [data, setData] = useState<ViewModel[]>([]);
  const [total, setTotal] = useState(0);
  const [mine, setMine] = useState<boolean>();
  const [filterStatus, setFilterStatus] = useState<QuoteOfferStatus | null>(null);
  const [dataState, setDataState] = useState<DataSourceRequestState>({
    skip: 0,
    take: 50,
    sort: [{
      field: "ResponseDateTime",
      dir: "desc"
    }],
    filter: { logic: 'and', filters: [] }
  });

  const refreshList = useCallback(() => {
    const queryStr = `${toDataSourceRequestString(dataState)}`;

    fetchApi<{ Data: ViewModel[], Total: number }>({
      url: `History_Read?${queryStr}`,
      method: 'POST',
      payload: { Status: filterStatus, Mine: mine }
    })
      .then(({ Data, Total }) => {
        setData(Data);
        setTotal(Total);
      });
    }, [filterStatus, mine, dataState])

    useEffect(() => {
      refreshList();
    }, [refreshList, filterStatus, mine, dataState]);

  const onDataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  }

  const columnProps = (field: string, headerClassName: string = ''): Partial<GridColumnProps> =>{
    return {
        field: field,
        columnMenu: ColumnMenu,
        headerClassName: isColumnActive(field, dataState) ? 'active ' + headerClassName : headerClassName
    };
  }

  const isColumnActive = (field: string, dataState: DataSourceRequestState) => {
    return GridColumnMenuFilter.active(field, dataState.filter);
  }

  const statusCell = (props: GridCellProps) => {
    switch (props.dataItem.Status) {
      case 1:
        return <td><Chip label="Declined" color="error" /></td>;
      case 2:
        return <td><Chip icon={props.dataItem.Awarded ? <StarIcon /> : props.dataItem.Confirmed ? <CheckIcon /> : undefined} label={formatNumber(props.dataItem.AllInRate, 'c0')} color="success" /></td>;
      case 5:
        return <td><Chip label="Withdrawn" color="error" /></td>;
      case 6:
        return <td><Chip label="Retracted" color="error" /></td>;
      default:
        return <td>N/A</td>;
    }
  }

  const expandChange = (e: GridExpandChangeEvent) => {
    setData((prevData) => {
      const expandedQuoteOffer = prevData.find(x => x.QuoteOfferID === e.dataItem.QuoteOfferID);
      if (expandedQuoteOffer) expandedQuoteOffer.expanded = e.value;
      return [...prevData];
    });
  }

  return <>
    <Grid
      size="small"
      reorderable
      //onColumnReorder={saveColumnOrder}
      dataItemKey="QuoteOfferID"
      sortable={{ allowUnsort: true }}
      pageable={{ pageSizes: [50, 100, 500] }}
      scrollable={'none'}
      onDataStateChange={onDataStateChange}
      detail={LoadBoardDetailRow}
      expandField="expanded"
      onExpandChange={expandChange}
      data={data}
      total={total}
      {...dataState}
    >
      <GridToolbar>
        <Button
          icon="filter-clear"
          title="Clear Filters and Sort"
          onClick={() => setDataState({
            skip: 0,
            take: 50,
            sort: [{
              field: "ResponseDateTime",
              dir: "desc"
            }],
            filter: { logic: 'and', filters: [] }
          })}
        />
        <Button
          icon="refresh"
          title="Refresh"
          onClick={refreshList}
        />
        <Button togglable
          themeColor={mine === true ? 'primary' : undefined}
          onClick={() => setMine(mine ? undefined : true)}
        >
          Mine
        </Button>
        <Button togglable
          themeColor={filterStatus === QuoteOfferStatus.Accepted ? 'primary' : undefined}
          onClick={() => setFilterStatus(filterStatus === QuoteOfferStatus.Accepted ? null : QuoteOfferStatus.Accepted)}
        >
          Bid
        </Button>
        <Button togglable
          themeColor={filterStatus === QuoteOfferStatus.Declined ? 'primary' : undefined}
          onClick={() => setFilterStatus(filterStatus === QuoteOfferStatus.Declined ? null : QuoteOfferStatus.Declined)}
        >
          Declined
        </Button>
        <Button togglable
          themeColor={filterStatus === QuoteOfferStatus.Retracted ? 'primary' : undefined}
          onClick={() => setFilterStatus(filterStatus === QuoteOfferStatus.Retracted ? null : QuoteOfferStatus.Retracted)}
        >
          Retracted
        </Button>
      </GridToolbar>
      <GridNoRecords>
        No Bids Found
      </GridNoRecords>
      <Column {...columnProps("QuoteID")} title="Ref" filter="numeric" />
      <Column {...columnProps("PickupCity")} title="Pickup" />
      <Column {...columnProps("PickupDateTime")} title="PU Date" filter="date" cell={DateTimeCell} />
      <Column {...columnProps("DeliveryCity")} title="Delivery" />
      <Column {...columnProps("DeliveryDateTime")} title="DEL Date" filter="date" cell={DateTimeCell} />
      <Column
        {...columnProps("TotalPieces", 'align-right')}
        title="Pieces"
        filter="numeric"
        cell={(e) => <td style={{ textAlign: 'right' }}>{formatNumber(e.dataItem.TotalPieces, 'n0')}</td>}
      />
      <Column {...columnProps("TotalWeight")} title="Weight" filter="numeric" format="{0:n0} lbs" />
      <Column
        {...columnProps("Distance", 'align-right')}
        title="Miles"
        filter="numeric"
        cell={(e) => <td style={{ textAlign: 'right' }}>{formatNumber(e.dataItem.Distance, 'n0')}</td>}
      />
      <Column
        {...columnProps("VehicleType")}
        title="Equipment"
        width={165}
        cell={(props) =>
          <td>
            {props.dataItem.CarrierVehicleNumber ? <div style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
              <span>{props.dataItem.VehicleType}&nbsp;</span>
              <Tooltip title={`Unit #${props.dataItem.CarrierVehicleNumber} Matched`}>
                <LocalShippingIcon color="warning" />
              </Tooltip>
            </div> : props.dataItem.VehicleType}
          </td>}
      />
      <Column {...columnProps("ResponseDateTime")} title="Bid Date" filter="date" cell={DateTimeCell} />
      <Column {...columnProps("EMailAddress")} title="Bid By" />
      <Column {...columnProps("AllInRate")} title="All-In Rate" filter="numeric" cell={statusCell} />
    </Grid>
  </>
}

export default BidHistory;