import { IconButton } from "@mui/material";
import { useEffect, useRef } from "react";

const HeadwayWidget = () => {
  const initialized = useRef(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true

      const script = document.createElement("script");
      script.async = true;
      script.src = "https://cdn.headwayapp.co/widget.js";
      document.head.appendChild(script);
      const config = {
        selector: "#headway-badge",
        account: "7Ldo87"
      };
      script.onload = function() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).Headway.init(config);
      }
    }
  }, []);

  return <IconButton id="headway-badge" />;
}

export default HeadwayWidget;