import { useEffect } from 'react';
import { useAuthDispatch } from 'contexts/auth/context';
import { Grid, Typography } from '@mui/material';

const Logout = () => {
  const { signOut } = useAuthDispatch();

  useEffect(() => {
    signOut();

    setTimeout(() => {
      window.location.href = '/';
    }, 2000);
  }, [signOut]);

  return <Grid container component="main" alignItems="center" justifyContent="center" mt={2}>
    <Typography variant="h4">Logging out...</Typography>
  </Grid>
}

export default Logout;