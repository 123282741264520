import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { useIdleTimer } from 'react-idle-timer'
import { HubConnection, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { process, CompositeFilterDescriptor, DataSourceRequestState, FilterDescriptor, State } from '@progress/kendo-data-query';
import groupBy from "lodash.groupby";
import random from "lodash.random";
import { useUpdateEffect, useLocalStorage } from 'usehooks-ts';
import { CarrierLoadBoardView } from "TypeGen/carrier-load-board-view";
import { LoadBoardDispatchProvider, LoadBoardStateProvider } from "./context";
import { LoadBoardDispatchContextData, LoadBoardStateContextData } from "./types";
import { baseUrl, fetchApi } from "core/fetchApi";
import { GridColumnMenuFilter } from "@progress/kendo-react-grid";
import { LoadOneReconnectPolicy } from "core/retryPolicy";
import { Snackbar } from "@mui/material";
import { parseJSON } from "date-fns";

const LoadBoardContainer: React.FC<PropsWithChildren> = ({ children }) => {

  const [connection, setConnection] = useState<HubConnection>();
  const [data, setData] = useState<CarrierLoadBoardView[]>([]);

  // Idle timer
  useIdleTimer({
    timeout: 1000 * 60 * 30, // 30 minutes
    onIdle: () => connection?.stop(),
    onActive: () => connection?.start().then(handleRefresh)
  });

  // Sound
  const [playSound, setPlaySound] = useState('');
  const [skipSound, setSkipSound] = useState(true);
  const [muteSound, ] = useLocalStorage('carrier-load-board-mute-sound', false);
  const [latestOfferDate, setLatestOfferDate] = useState(0);

  const processFilters = (filters: Array<FilterDescriptor>): CompositeFilterDescriptor => {
    // Group filters by field
    const groupedFilters = groupBy(filters, filter => filter.field);
    // Create a filter for each group
    const compositeFilters = Object.keys(groupedFilters).map(key => {
      const filters = groupedFilters[key];
      // If there is only one filter for the field, return it
      if (filters.length === 1) {
        return filters[0];
      }
      // Otherwise, return a composite filter
      return {
        logic: 'or',
        filters
      } as CompositeFilterDescriptor;
    }).filter(x => x !== undefined);
    return {
      logic: 'and',
      filters: compositeFilters
    } as CompositeFilterDescriptor;
  }
  let initialFilters = [];
  const sessionStorageFilters = sessionStorage.getItem("CarrierLoadBoard-filters");
  if (sessionStorageFilters) {
    initialFilters = JSON.parse(sessionStorageFilters);
  }
  const [filters, setFilters] = useState<FilterDescriptor[]>(initialFilters);
  const [dataState, setDataState] = useState<DataSourceRequestState>({
    skip: 0,
    take: 50,
    sort: [{ field: "ExpiryDateTime", dir: "asc" }],
    filter: processFilters(filters)
  });

  const handleRefresh = useCallback(() => {
    if (connection?.state === HubConnectionState.Connected) {
      connection.invoke('read')
        .then((shipments: Omit<CarrierLoadBoardView, 'expanded'>[]) => {
          setData((prevState) => shipments.map(s => {
            const expanded = prevState.find(p => p.QuoteOfferID === s.QuoteOfferID)?.expanded;
            s.PickupDateTime = parseJSON(s.PickupDateTime);
            s.DeliveryDateTime = parseJSON(s.DeliveryDateTime);
            return { ...s, expanded };
          }));
          setTimeout(() => setSkipSound(false), 3000);
        });
    }
  }, [connection]);

  useEffect(() => {
    const builder = new HubConnectionBuilder()
      .withUrl(`${baseUrl}/carrierapp`, {
        accessTokenFactory: () => localStorage.getItem("TOKEN") as string
      })
      .withAutomaticReconnect(new LoadOneReconnectPolicy())
      .build();

    setConnection(builder)
  }, []);

  useEffect(() => {
    async function startConnection() {
      try {
        connection?.onreconnected(() => {
          handleRefresh();
        });

        connection?.on('carrierVehicleNumber', (update: { QuoteID: number, CarrierVehicleNumber: string }) => {
          setData((prevState) => prevState.map(s => {
            if (s.QuoteID === update.QuoteID) {
              return { ...s, CarrierVehicleNumber: update.CarrierVehicleNumber };
            }
            return s;
          }));
        });

        connection?.on('destroy', (shipments: { QuoteID: number } | Array<{ QuoteID: number }>) => {
          // Remove shipment from data
          if (Array.isArray(shipments)) {
            setData((prevState) => prevState.filter(s => !shipments.find(d => d.QuoteID === s.QuoteID)));
          } else {
            setData((prevState) => prevState.filter(s => s.QuoteID !== shipments.QuoteID));
          }
        });

        connection?.on('refresh', () => {
          // refresh in the next 0-2 seconds
          setTimeout(handleRefresh, random(0, 2000));
        });

        connection?.on('notification', (notification: { Title: string, Options: NotificationOptions }) => {
          createHtmlNotification(notification.Title, notification.Options);
        });

        connection?.on('logout', () => {
          window.location.href = '/logout';
        });

        await connection?.start();

        handleRefresh();
      } catch (error) {
        console.error(error)
      }
    }

    if (connection) {
      startConnection()
    }

    return () => {
      connection?.stop()
    }
  }, [connection, handleRefresh]);

  const gridData = process(data, dataState);
  const newLatestOfferDate = useMemo(() => Math.max(latestOfferDate, ...gridData.data.map(x => Date.parse(x.OfferDateTime))), [gridData.data, latestOfferDate]);
  useUpdateEffect(() => {
      setLatestOfferDate(newLatestOfferDate);
      setPlaySound('/sounds/newChatMessage.mp3');
      if (!skipSound) {
        const newQuote = data.find(x => Date.parse(x.OfferDateTime) === newLatestOfferDate) as CarrierLoadBoardView;
        createHtmlNotification(`${newQuote.VehicleType} Requested`, { tag: `n-${newQuote.QuoteOfferID}`, silent: true });
      }
  }, [newLatestOfferDate]);

  const handleSetExpand = useCallback((quoteOffer: CarrierLoadBoardView, value: boolean) => {
    setData((prevData) => {
      const expandedQuoteOffer = prevData.find(x => x.QuoteOfferID === quoteOffer.QuoteOfferID);
      if (expandedQuoteOffer) expandedQuoteOffer.expanded = value
      return [...prevData];
    });
  }, []);

  const handleDecline = useCallback((quoteOffer: CarrierLoadBoardView) => {
    setData((prevData) => prevData.filter(x => x.QuoteID !== quoteOffer.QuoteID));
    fetchApi({ url: 'LoadBoardDecline', method: "POST", payload: { QuoteID: quoteOffer.QuoteID, QuoteOfferID: quoteOffer.QuoteOfferID } })
      .catch(() => {
        handleRefresh();
      });
  }, [handleRefresh]);

  const handleDataStateChange = useCallback((dataState: State) => {
    setDataState(dataState);
  }, []);

  const handleReset = useCallback(() => {
    setFilters([]);
    handleDataStateChange({
      skip: 0,
      take: 50,
      sort: [{field: "ExpiryDateTime", dir: "asc"}],
      filter: { logic: 'and', filters: [] } as CompositeFilterDescriptor
    });
    sessionStorage.removeItem("CarrierLoadBoard-filters");
  }, [handleDataStateChange]);

  const handleIsColumnActive = useCallback((field: string) => {
    return GridColumnMenuFilter.active(field, dataState.filter);
  }, [dataState.filter]);

  const handleHasFilter = useCallback((filter: FilterDescriptor) => {
    return filters
      .filter(x => JSON.stringify(x) === JSON.stringify(filter))
      .length > 0;
  }, [filters]);

  const handleToggleFilter = useCallback((filter: FilterDescriptor) => {
    let newFilters: FilterDescriptor[] = [];
    if (handleHasFilter(filter)) {
      // Remove existing
      newFilters = filters.filter(x => JSON.stringify(x) !== JSON.stringify(filter));
    } else {
      // Add new
      newFilters = [...filters, filter];
    }
    handleDataStateChange({
      skip: 0,
      take: dataState.take,
      sort: dataState.sort,
      filter: processFilters(newFilters)
    });
    setFilters(newFilters);
    sessionStorage.setItem("CarrierLoadBoard-andFilter", JSON.stringify(newFilters));
  }, [filters, handleDataStateChange, handleHasFilter, dataState.take, dataState.sort]);

  const createHtmlNotification = (title: string, options: NotificationOptions) => {
    if ('Notification' in window) {
      Notification.requestPermission((status) => {
          if (status !== 'denied') {
            options.icon = options.icon || '/images/notification.png';
            options.lang = options.lang || 'en-US';
            new Notification(title, options).onclick = () => window.focus();
          }
      });
    }
  }

  const loadboardState = useMemo<LoadBoardStateContextData>(
    () => ({
      dataState: dataState,
      gridData: gridData,
    }),
    [
      dataState,
      gridData,
    ],
  );

  const loadboardDispatch = useMemo<LoadBoardDispatchContextData>(
    () => ({
      refresh: handleRefresh,
      reset: handleReset,
      setExpand: handleSetExpand,
      decline: handleDecline,
      changeDataState: handleDataStateChange,
      isColumnActive: handleIsColumnActive,
      toggleFilter: handleToggleFilter,
      hasFilter: handleHasFilter,
    }),
    [
      handleRefresh,
      handleReset,
      handleSetExpand,
      handleDecline,
      handleDataStateChange,
      handleIsColumnActive,
      handleToggleFilter,
      handleHasFilter
    ],
  );

  return (
    <LoadBoardStateProvider value={loadboardState}>
      <LoadBoardDispatchProvider value={loadboardDispatch}>
        {playSound && <audio muted={skipSound || muteSound} src={playSound} onEnded={() => setPlaySound('') } autoPlay/>}
        {children}
        <Snackbar
          open={!connection || connection.state !== HubConnectionState.Connected}
          autoHideDuration={null}
          message={connection ? connection.state : 'Connecting.'}
        />
      </LoadBoardDispatchProvider>
    </LoadBoardStateProvider>
  );
}

export default LoadBoardContainer;