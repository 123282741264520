import * as React from 'react';
import { ILink } from '../../core/link';
import { MaskedTextBox } from '@progress/kendo-react-inputs';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { JsonResponse } from 'TypeGen/json-response';
import { fetchApi } from 'core/fetchApi';
import { formatDateTime, formatPhone } from 'utils/format';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

type TrackingData = {
  PhoneNumber: string;
  CreatedByFullName: string;
  CreatedDateTime: string | null;
  LastLocation: string | null;
  Devices: Array<{
    DeviceName: string;
    CreatedDateTime: string | null;
    LastRefreshDateTime: string | null;
  }>;
  Links: ILink[];
}

type SetupRequest = {
  PhoneNumber: string;
}

type Props = {
  Link: ILink;
  CloseDialog: () => void;
}

type State = {
  loading: boolean;
  sending: boolean;
  data: null | TrackingData;
  request: SetupRequest;
}

export default class AppTracking extends React.Component<Props, State> {

  private phoneInput: React.RefObject<MaskedTextBox>;

  constructor(props: Props) {
    super(props);

    this.phoneInput = React.createRef();

    this.state = {
      loading: true,
      sending: false,
      data: null,
      request: {
        PhoneNumber: '',
      }
    }

    this.refresh = this.refresh.bind(this);
    this.setup = this.setup.bind(this);
    this.remove = this.remove.bind(this);
    this.resend = this.resend.bind(this);
  }

  public componentDidMount() {
    this.refresh();
  }

  private refresh() {
    this.setState({ loading: true });
    fetchApi<TrackingData>({ url: this.props.Link.Link, method: this.props.Link.Method })
      .then((data) => {
        this.setState({ data, loading: false }, () => {
          if (this.phoneInput.current) {
            this.phoneInput.current.focus();
          }
        });
      });
  }

  render() {
    if (this.state.loading || !this.state.data) {
      return <Dialog title="Loading..." onClose={this.props.CloseDialog} className='dialog-w800' />;
    }
    return (
      <Dialog title="Load One App Tracking Setup" onClose={this.props.CloseDialog} className='dialog-w800'>
        {this.state.data.Links.find(x => x.Name == "Setup") && <>
          <MaskedTextBox
            ref={this.phoneInput}
            required={true}
            label="Driver's Cell Phone"
            validationMessage="Please enter a valid phone number!"
            mask="(000) 000-0000"
            className="flex-fill"
            value={this.state.request.PhoneNumber}
            onChange={(e) => e.value === '(___) ___-____' ? this.updateRequest({ PhoneNumber: '' }) : this.updateRequest({ PhoneNumber: e.value })}
          />
        </>}
        {this.state.data.Links.find(x => x.Name == "Remove") && <>
          <p>
            Driver's Phone <b><a href={`tel:${this.state.data.PhoneNumber}`}>{formatPhone(this.state.data.PhoneNumber)}</a></b><br />
            Setup by <b>{this.state.data.CreatedByFullName}</b> at <b>{formatDateTime(this.state.data.CreatedDateTime!)}</b><br />
            Last Position Update <b>{this.state.data.LastLocation ? formatDateTime(this.state.data.LastLocation) : 'N/A'}</b>
          </p>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Device Name</TableCell>
                <TableCell>Created/Login</TableCell>
                <TableCell>Last Contact</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.data.Devices.map(x => <TableRow key={x.DeviceName}>
                <TableCell>{x.DeviceName}</TableCell>
                <TableCell>{x.CreatedDateTime ? formatDateTime(x.CreatedDateTime) : 'N/A'}</TableCell>
                <TableCell>{x.LastRefreshDateTime ? formatDateTime(x.LastRefreshDateTime) : 'N/A'}</TableCell>
              </TableRow>)}
            </TableBody>
          </Table>
        </>}
        <DialogActionsBar layout="end">
          <Button onClick={this.props.CloseDialog}>Cancel</Button>
          {this.state.data.Links.find(x => x.Name == "Resend") && <Button themeColor="primary" onClick={this.resend} disabled={this.state.sending}>Resend Link</Button>}
          {this.state.data.Links.find(x => x.Name == "Setup") && <Button themeColor="primary" onClick={this.setup} disabled={this.state.sending}>Setup</Button>}
          {this.state.data.Links.find(x => x.Name == "Remove") && <Button themeColor="error" onClick={this.remove} disabled={this.state.sending}>Stop Tracking</Button>}
        </DialogActionsBar>
      </Dialog>
    );
  }

  private updateRequest(value: Partial<SetupRequest>) {
    const request = Object.assign({}, this.state.request, value);
    this.setState({ request });
  }

  private resend() {
    const link = this.state.data?.Links.find(x => x.Name == "Resend") as ILink;
    this.setState({ sending: true });
    fetchApi<JsonResponse>({ url: link.Link, payload: { PhoneNumber: this.state.data?.PhoneNumber }, method: link.Method })
      .then((response) => {
        if (response.Success) {
          this.props.CloseDialog();
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch(() => alert("Unknown Error Occurred!"))
      .finally(() => this.setState({ sending: false }));
  }

  private setup() {
    if (this.phoneInput.current?.validity.valid === false) {
      alert("Please enter a valid phone number.");
      return;
    }
    const link = this.state.data?.Links.find(x => x.Name == "Setup") as ILink;
    this.setState({ sending: true });
    fetchApi<JsonResponse>({ url: link.Link, payload: this.state.request, method: link.Method })
      .then((response) => {
        if (response.Success) {
          this.props.CloseDialog();
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch(() => alert("Unknown Error Occurred!"))
      .finally(() => this.setState({ sending: false }));
  }

  private remove() {
    const link = this.state.data?.Links.find(x => x.Name == "Remove") as ILink;
    this.setState({ sending: true });
    fetchApi<JsonResponse>({ url: link.Link, method: link.Method })
      .then((response) => {
        if (response.Success) {
          this.props.CloseDialog();
        } else {
          alert(response.ErrorMessage);
        }
      })
      .catch(() => alert("Unknown Error Occurred!"))
      .finally(() => this.setState({ sending: false }));
  }
}
