import { debounce } from '@mui/material';
import { AutoComplete, AutoCompleteProps, ListItemProps } from '@progress/kendo-react-dropdowns';
import { ISingleSearchLocation, singleSearch } from 'core/pcmiler';
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

type Props = {
  value?: string,
  onSelected: (location: ISingleSearchLocation | null) => void;
  autoCompleteProps?: AutoCompleteProps;
}

const SingleLocationAutoComplete = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [locationDropDownItems, setLocationDropDownItems] = useState<ISingleSearchLocation[]>([]);
  const [locationAutoCompleteValue, setLocationAutoCompleteValue] = useState(props.value);
  const _requestHash = useRef<string>('');

  useEffect(() => {
    setLocationAutoCompleteValue(props.value);
  }, [props.value]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearchInput = useCallback(debounce((val: string, requestHash: string) => {
    if (val.length < 4) return;

    setLoading(true);
    singleSearch(val)
      .then((response) => {
        const locationData: ISingleSearchLocation[] = response.Locations;
        if (_requestHash.current === requestHash) {
          setLocationDropDownItems(locationData);
        }
        setLoading(false);
      })
      .catch(() => {
        if (_requestHash.current === requestHash) {
          setLocationDropDownItems([]);
        }
        setLoading(false);
      });
  }, 750), []);

  const itemRenderLocation = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
    const location = itemProps.dataItem as ISingleSearchLocation;
    const itemChildren = <span>{location.ShortString}</span>;
    return React.cloneElement(li, li.props, itemChildren);
  }

  const listNoDataRender = (element: React.ReactElement<HTMLDivElement>) => {
    const noData = (
      <h4 style={{ fontSize: "1em" }}>
      <span className="k-icon k-i-warning" style={{ fontSize: "2.5em" }} />
      <br />
      Location Not Found
      </h4>
    );
    return React.cloneElement(element, { ...element.props }, noData);
  };

  return <AutoComplete
    {...props.autoCompleteProps}
    placeholder={(loading ? 'loading...' : props.autoCompleteProps?.placeholder)}
    loading={loading}
    disabled={loading || props.autoCompleteProps?.disabled}
    data={locationDropDownItems}
    itemRender={itemRenderLocation}
    value={locationAutoCompleteValue}
    listNoDataRender={listNoDataRender}
    onChange={(e) => {
      if (typeof e.value === 'string') {
        setLocationDropDownItems([]);
        setLocationAutoCompleteValue(e.value);

        //handles the clearing on input
        if (!e.value) props.onSelected(null);

        const requestHash = Math.random().toString(36).substring(7);
        _requestHash.current = requestHash;
        debounceSearchInput(e.value, requestHash);
      } else {
        const location = e.value as ISingleSearchLocation;
        setLocationAutoCompleteValue(`${location.Address.Zip} ${location.Address.City}, ${location.Address.State}`);
        props.onSelected(location)
      }
    }}
  />
}

export default SingleLocationAutoComplete;
