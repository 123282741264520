import { Grid, GridColumn as Column, GridExpandChangeEvent, GridToolbar, GridNoRecords } from "@progress/kendo-react-grid";
import { fetchApi } from "core/fetchApi";
import { useCallback, useEffect, useState } from "react";
import { ILink } from "core/link";
import { useInterval } from "usehooks-ts";
import TrackDetailRow from "./DetailRow";
import JustifyEndHeaderCell from "components/JustifyEndHeaderCell";
import ReferenceCell from "./ReferenceCell";
import PickupCell from "./PickupCell";
import DeliveryCell from "./DeliveryCell";
import StatusCell from "./StatusCell";
import AppTrackingCell from "./AppTrackingCell";
import NextStopCell from "./NextStopCell";
import ETACell from "./ETACell";
import PositionCell from "./PositionCell";
import DistanceCell from "./DistanceCell";
import PayCell from "./PayCell";
import { Button } from "@progress/kendo-react-buttons";

export type ViewModel = {
  OrderID: number;
  OrderNumber: number;
  TripID: number;
  TripNumber: number;
  ReferenceNumber1: string;
  OrderStopStatus: number;
  OrderStopEvent: number;
  VehicleSpeed: number;
  PickupDateTime: Date;
  PickupLocation: string;
  DeliveryDateTime: Date;
  DeliveryLocation: string;
  Verb: string;
  PositionLocation: string;
  PositionRepeatCount: number;
  ScheduledDateTime: Date;
  EarliestStopDateTime: Date | null;
  LatestStopDateTime: Date | null;
  PositionDateTime: Date;
  ETADateTime: Date | null;
  ETAMiles: number;
  MinutesLate: number;
  LoadedMiles: number;
  StopCount: number;
  StopSequence: number;
  RequiredSpeed: number;
  IsRunningLate: boolean;
  IsRunningLatePickup: boolean;
  IsRunningLateDelivery: boolean;
  IsScheduledSoon: boolean;
  TimeCriticalMinutes: number;
  IsCritical: boolean;
  NeedsCheckCall: boolean;
  WaitingAtArrivedMinutes: number;
  TotalPay: number;
  BilledVehicleTypeName: string;
  DriverAppCarrierHours: number | null;
  Links: ILink[];
  Hash: string;

  expanded?: boolean;
}

const Track = () => {

  const [data, setData] = useState<ViewModel[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchRecords = useCallback(() => {
    setLoading(true);
    fetchApi<{ Trips: Omit<ViewModel, 'expanded'>[] }>({ url: 'Grid', method: 'POST' })
      .then(({ Trips }) => {
        setData((prevState) => Trips.map(s => {
          const expanded = prevState.find(p => p.TripID === s.TripID)?.expanded;
          return { ...s, expanded };
        }));
        setLoading(false);
      }).catch(() => {
        setLoading(false);
        alert('Error fetching data.');
      });
  }, []);

  useEffect(() => {
    fetchRecords();
  }, [fetchRecords]);

  // refresh every 10 minutes
  useInterval(fetchRecords, 1000 * 60 * 10);

  const expandChange = (e: GridExpandChangeEvent) => {
    setData((prevData) => {
      const expandedTrip = prevData.find(x => x.TripID === e.dataItem.TripID);
      if (expandedTrip) expandedTrip.expanded = e.value;
      return [...prevData];
    });
  }

  return <>
    {loading && <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>}
    <Grid
      data={data}
      size="small"
      scrollable="none"
      dataItemKey="TripID"
      detail={TrackDetailRow}
      expandField="expanded"
      onExpandChange={expandChange}
    >
      <GridToolbar>
        <Button
          icon="refresh"
          title="Refresh"
          onClick={fetchRecords}
        />
      </GridToolbar>
      <GridNoRecords>
        No In-Progress Trips Found
      </GridNoRecords>
      <Column field="OrderNumber" title="Reference" filter="numeric" cell={ReferenceCell} />
      <Column field="PickupDateTime" title="Pickup" filter="date" cell={PickupCell} />
      <Column field="DeliveryDateTime" title="Delivery" filter="date" cell={DeliveryCell} />
      <Column field="OrderStopStatus" title="Status" filter="numeric" cell={StatusCell} />
      <Column title="Tracking" sortable={false} filterable={false} cell={AppTrackingCell} />
      <Column field="ScheduledDateTime" title="Next Stop" cell={NextStopCell} />
      <Column field="ETADateTime" title="ETA" filter="date" cell={ETACell} />
      <Column field="PositionDateTime" title="Last Position" cell={PositionCell} />
      <Column field="ETAMiles" title="Miles" cell={DistanceCell} headerCell={JustifyEndHeaderCell} />
      <Column field="TotalPay" title="Pay" cell={PayCell} headerCell={JustifyEndHeaderCell} />
    </Grid>
  </>;
}

export default Track;