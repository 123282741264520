import { GridCellProps } from '@progress/kendo-react-grid';
import * as React from 'react';
import { parseJSON } from 'date-fns';
import { format } from 'date-fns-tz';
import { ViewModel } from '.';

const PickupCell = (props: GridCellProps) => {
  const data = props.dataItem as ViewModel;
  return (
    <td>
      {data.PickupLocation}<br />
      {format(parseJSON(data.PickupDateTime), 'LL/dd/yyyy HH:mm zzz')}
    </td>
  );
}
export default React.memo(PickupCell, (prev, next) => prev.dataItem.Hash === next.dataItem.Hash);