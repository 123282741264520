import { IRetryPolicy, RetryContext } from '@microsoft/signalr';

export class LoadOneReconnectPolicy implements IRetryPolicy {
  public nextRetryDelayInMilliseconds(retryContext: RetryContext): number | null {
    if (retryContext.previousRetryCount >= 10) {
      // Could be expired JWT token
      location.reload();
      return null;
    }
    return Math.min(retryContext.previousRetryCount * 2000, 30000);
  }
}