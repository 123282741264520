import { GridCellProps } from '@progress/kendo-react-grid';
import * as React from 'react';
import { parseJSON } from 'date-fns';
import { format } from 'date-fns-tz';
import { ViewModel } from '.';
import Positions from './TripPositions';

const PositionCell = (props: GridCellProps) => {
  const [showPositions, setShowPositions] = React.useState(false);
  const data = props.dataItem as ViewModel;
  return (
    <td>
      {showPositions && <Positions tripId={data.TripID} close={() => setShowPositions(false)} />}
      <a
        href="#"
        style={{ color: (data.NeedsCheckCall && data.OrderStopStatus != 115 ? 'red' : '#007bff') }}
        onClick={(e) => {
          setShowPositions(true);
          e.preventDefault();
        }}
      >
        {format(parseJSON(data.PositionDateTime), 'LL/dd/yyyy HH:mm zzz')}
      </a><br />
      <span
        style={{ color: (data.PositionRepeatCount >= 7 ? 'red' : undefined) }}
      >
        {data.PositionLocation}
        {data.PositionRepeatCount > 1 && ` (${data.PositionRepeatCount}x)`}
      </span>
    </td>
  );
}
export default React.memo(PositionCell, (prev, next) => prev.dataItem.Hash === next.dataItem.Hash);