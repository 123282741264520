

import { GridCellProps } from '@progress/kendo-react-grid';
import * as React from 'react';
import { ViewModel } from '.';

const StatusCell = (props: GridCellProps) => {
  const data = props.dataItem as ViewModel;

  const orderStopStatusName = (status: number, event: number) => {
    switch (status) {
      case 100:
        return 'Not Started';
      case 110:
        return `Enroute to ${(event === 1) ? 'PU' : 'DEL'}`;
      case 115:
        return `Arrived at ${(event === 1) ? 'PU' : 'DEL'}`;
      case 120:
        return 'Completed';
      default:
        return 'On Route'; // Deadhead
    }
  }

  return (
    <td>
      {data.StopSequence} of {data.StopCount}<br />
      {orderStopStatusName(data.OrderStopStatus, data.OrderStopEvent)}
    </td>
  );
}
export default React.memo(StatusCell, (prev, next) => prev.dataItem.Hash === next.dataItem.Hash);