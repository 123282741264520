import React from 'react';
import ReactDOM from 'react-dom/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as Sentry from "@sentry/react";
import './index.scss';
import App from './App';
import AuthProvider from './contexts/auth/provider';
import 'hammerjs';

Sentry.init({
  dsn: "https://e04d94ec84892926b73acb1b97017b67@o261143.ingest.sentry.io/4506717405708288",
  integrations: [
    Sentry.browserTracingIntegration(),
  ],
  tracesSampleRate: 0.02,
  tracePropagationTargets: ["localhost", /^https:\/\/app\.load1\.com\/api/],
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      showDialog
      dialogOptions={{
        onClose: () => location.reload()
      }}
    >
      <AuthProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <App />
        </LocalizationProvider>
      </AuthProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);
