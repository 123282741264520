import { Chart, ChartAxisDefaults, ChartCategoryAxis, ChartCategoryAxisItem, ChartLegend, ChartSeries, ChartSeriesItem, ChartTitle, ChartValueAxis, ChartValueAxisItem } from '@progress/kendo-react-charts';
import { Stepper, Step, StepLabel, StepConnector, stepConnectorClasses, styled, Box, Alert, Divider } from '@mui/material';
import { formatNumber } from '@progress/kendo-intl';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';

export type PerformanceResponse = {
  PeriodName: string;
  Tier: CarrierTier;
  Reason: string;
  CarrierName: string;
  StopPerformance: StopPerformance;
  PositionPerformance: PositionPerformance;
  Revenue: Revenue;
  AppTracking: CarrierAppTracking;
}

enum CarrierTier {
  Platinum = 10,
  Gold = 20,
  Silver = 30,
  NA = 0
}

type StopPerformance = {
  AvgLateMinutes: number;
  PercentOnTime: number;
  Labels: string[];
  OnTime: number[];
  LatePU: number[];
  LateDR: number[];
}

type PositionPerformance = {
  PercentPositionUpdates: number;
  Labels: string[];
  Provided: number[];
  Missing: number[];
}

type Revenue = {
  Total: number;
  TotalTrips: number;
  Labels: string[];
  Revenue: number[];
}

type CarrierAppTracking = {
  PercentAppAccepted: number;
  Labels: string[];
  Requested: number[];
  Accepted: number[];
}

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const Performance = () => {

  const navigate = useNavigate();
  const { period = "0" } = useParams();
  const data = useLoaderData() as PerformanceResponse;

  const navigatePeriod = (forward: boolean) => {
    const nextPeriod = parseInt(period) + (forward ? 1 : -1);
    nextPeriod === 0 ? navigate('/performance') : navigate(`/performance/${nextPeriod}`);
  }

  return (
    <>
      <Box display="flex" justifyContent="center" my={2}>
        <ButtonGroup>
          <Button disabled>{data.PeriodName}</Button>
          <Button icon="arrow-chevron-left" onClick={() => navigatePeriod(false)} />
          <Button icon="arrow-chevron-right" onClick={() => navigatePeriod(true)} />
        </ButtonGroup>
      </Box>
      <Stepper nonLinear alternativeLabel connector={<CustomStepConnector />}>
        <Step completed>
          <StepLabel icon=" ">Bronze Tier</StepLabel>
        </Step>
        <Step completed={data.Tier !== 0 && data.Tier <= 30}>
          <StepLabel icon=" ">Silver Tier</StepLabel>
        </Step>
        <Step completed={data.Tier !== 0 && data.Tier <= 20}>
          <StepLabel icon=" ">Gold Tier</StepLabel>
        </Step>
        <Step last completed={data.Tier !== 0 && data.Tier <= 10}>
          <StepLabel icon=" ">Platinum Tier</StepLabel>
        </Step>
      </Stepper>
      <Box
        display="flex"
        justifyContent="center"
        mt={2}
        mb={2}
      >
        {!data.StopPerformance && !data.PositionPerformance && !data.Revenue && <Alert severity="info">
          Insufficient data to display.
        </Alert>}
        {data.Reason && <Alert severity="warning">
          {data.Reason}
        </Alert>}
      </Box>
      {data.StopPerformance && <>
        <Chart transitions={false}>
          {data.StopPerformance.PercentOnTime ? <ChartTitle position='top' text={`On-Time Performance (${formatNumber(data.StopPerformance.PercentOnTime, "p")} Trips On-Time)`} />
             : <ChartTitle position='top' text={`On-Time Performance (When Late, Averages ${formatNumber(data.StopPerformance.AvgLateMinutes, '0')} mins. late)`} />}
          <ChartLegend position="bottom" orientation="horizontal" />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem categories={data.StopPerformance.Labels} />
          </ChartCategoryAxis>
          <ChartSeries>
            <ChartSeriesItem type="column" color="#2ecc71" name="On-Time" data={data.StopPerformance.OnTime} stack />
            <ChartSeriesItem type="column" color="#ffc107" name="PU Late" data={data.StopPerformance.LatePU} />
            <ChartSeriesItem type="column" color="#dc3545" name="DR Late" data={data.StopPerformance.LateDR} />
          </ChartSeries>
        </Chart>
        <Divider />
      </>}
      {data.PositionPerformance && <>
        <Chart transitions={false}>
          <ChartTitle position='top' text={`Position Update Performance (${formatNumber(data.PositionPerformance.PercentPositionUpdates, "p")} Trips Provided)`} />
          <ChartLegend position="bottom" orientation="horizontal" />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem categories={data.PositionPerformance.Labels} />
          </ChartCategoryAxis>
          <ChartSeries>
            <ChartSeriesItem type="column" color="#2ecc71" name="Provided" data={data.PositionPerformance.Provided} stack />
            <ChartSeriesItem type="column" color="#dc3545" name="No Positions for 2 Hours" data={data.PositionPerformance.Missing} />
          </ChartSeries>
        </Chart>
        <Divider />
      </>}
      {data.Revenue && <>
        <Chart transitions={false}>
          <ChartAxisDefaults
            labels={{
              format: "c0",
            }}
          />
          <ChartValueAxis>
            <ChartValueAxisItem crosshair={{ visible: true}} />
          </ChartValueAxis>
          <ChartTitle position='top' text={`Billed Charges (${data.Revenue.TotalTrips} Trips @ ${formatNumber(data.Revenue.Total, "c0")})`} />
          <ChartLegend position="bottom" orientation="horizontal" />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem categories={data.Revenue.Labels} />
          </ChartCategoryAxis>
          <ChartSeries>
            <ChartSeriesItem type="column" color="#2ecc71" name="Daily Revenue" data={data.Revenue.Revenue} />
          </ChartSeries>
        </Chart>
        <Divider />
      </>}
      {data.AppTracking && <>
        <Chart transitions={false}>
          <ChartTitle position='top' text={`App Tracking (${formatNumber(data.AppTracking.PercentAppAccepted, "p")} Accepted)`} />
          <ChartLegend position="bottom" orientation="horizontal" />
          <ChartCategoryAxis>
            <ChartCategoryAxisItem categories={data.AppTracking.Labels} />
          </ChartCategoryAxis>
          <ChartSeries>
            <ChartSeriesItem type="line" color="#dc3545" name="Requested" data={data.AppTracking.Requested} />
            <ChartSeriesItem type="line" color="#2ecc71" name="Accepted" data={data.AppTracking.Accepted} />
          </ChartSeries>
        </Chart>
      </>}
    </>
  );
}

export default Performance;