import { GridCellProps } from '@progress/kendo-react-grid';
import * as React from 'react';
import { ViewModel } from '.';

const ReferenceCell = (props: GridCellProps) => {
  const data = props.dataItem as ViewModel;
  return (
    <td>
      {data.OrderNumber}<br />
      {data.ReferenceNumber1}
    </td>
  );
}
export default React.memo(ReferenceCell, (prev, next) => prev.dataItem.Hash === next.dataItem.Hash);