import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import Login from './screens/Login';
import LoadBoard from './screens/LoadBoard';
import LoadBoardNotifications from 'screens/LoadBoard/Notifications';
import BidHistory from './screens/LoadBoard/history';
import Track from './screens/Track';
import Performance from './screens/Performance';
import ServiceExceptions from './screens/ServiceExceptions';
import Settings from './screens/Settings';
import Signup from './screens/Signup';
import Logout from 'screens/Logout';
import Impersonate from './screens/Impersonate';
import ProtectedRoute from './components/ProtectedRoute';
import { useAuthDispatch, useAuthState } from './contexts/auth/context';
import { useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { fetchApi } from './core/fetchApi';
import { Box } from '@mui/material';
import Layout from 'components/layout';
import './App.css';

function App() {
  const theme = createTheme({
    palette: {
      mode: 'dark',
      error: {
        main: '#f44336',
        light: '#e57373',
        dark: '#d32f2f',
        contrastText: 'rgba(0, 0, 0, 0.87)',
      }
    },
  });
  const { refresh } = useAuthDispatch();
  const { initializing, token, waitingVerification, refreshToken } = useAuthState();

  // Refresh Token on App Launch / Login
  useEffect(() => {
    if (refreshToken && !waitingVerification) refresh();
  }, [refreshToken, waitingVerification, refresh]);

  const router = createBrowserRouter([
    {
      path: "/",
      element: token ? <Navigate to="/loadboard" /> : <Login />,
    },
    {
      path: "/signup",
      element: token ? <Navigate to="/" replace /> : <Signup />,
    },
    {
      path: "/logout",
      element: <Logout />,
    },
    {
      path: "/impersonate/:email/:refreshToken",
      element: <Impersonate />,
    },
    {
      element: <ProtectedRoute><Layout /></ProtectedRoute>,
      children: [
        {
          path: "/loadboard",
          element: <LoadBoard />,
        },
        {
          path: "/loadboard/notifications",
          loader: () => {
            return token ? fetchApi({ url: 'LoadBoardNotifications' }) : false;
          },
          element: <LoadBoardNotifications />,
        },
        {
          path: "/bidhistory",
          element: <BidHistory />,
        },
        {
          path: "/track",
          element: <Track />,
        },
        {
          path: "/performance/:period?",
          loader: ({ params }) => {
            return token ? fetchApi({ url: 'Performance/' + (params.period || 0) }) : false;
          },
          element: <Performance />,
        },
        {
          path: "/exceptions",
          element: <ServiceExceptions />,
        },
        {
          path: "/settings",
          loader: () => {
            return token ? fetchApi({ url: 'Settings' }) : false;
          },
          element: <Settings />,
        },
      ]
    },
    {
      path: "*",
      element: <Navigate replace to="/" />,
    },
  ]);

  return <ThemeProvider theme={theme}>
    <Box sx={{ display: 'flex' }}>
      <CssBaseline enableColorScheme />
      {!initializing && <RouterProvider router={router} />}
    </Box>
  </ThemeProvider>;
}

export default App;
