import { useCallback, useEffect, useState } from "react";
import { DataSourceRequestState, toDataSourceRequestString } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { JsonResponse } from "TypeGen/json-response";
import ManualPositionPopup, { NewPositionType } from "./ManualPositionPopup";
import { fetchApi } from "core/fetchApi";
import DateTimeCell from "components/DateTimeCell";

type PositionsType = {
  Data: PositionType[],
  Total: number,
}

type PositionType = {
  PositionID: number;
  VehicleID: number;
  TripID: number | null;
  Event: number;
  DateTime: string;
  City: string;
  State: string;
  Latitude: number;
  Longitude: number;
  Direction: number;
  Speed: number;
  Ignition: boolean | null;
  RepeatCount: number;
  UserNumber: string;
  TripNumber: number | null;
  VehicleNumber: string;
}

const resetDataState = {
  skip: 0,
  take: 5,
  sort: [{
    field: "DateTime",
    dir: "desc"
  }],
} as DataSourceRequestState;


const positionEventName = (event: number) => {
  switch (event) {
    case 2:
      return "Dispatched Order";
    case 3:
      return "Arrive Shipper";
    case 4:
      return "Freight Loaded";
    case 7:
      return "Arrive Consignee";
    case 8:
      return "Empty";
    case 17:
      return "Driver Check Call";
    case 18:
      return "Dispatch DeadHead";
    case 19:
      return "Empty DeadHead";
    case 61:
      return "Tracking On";
    case 62:
      return "Tracking Off";
    default:
      return event;
  }
}

type Props = {
  tripId: number;
  close: () => void;
}

const TripPositions = ({ tripId, close }: Props) => {

    const [loading, setLoading] = useState(true);
    const [showNewPosition, setShowNewPosition] = useState(false);
    const [positions, setPositions] = useState<PositionsType>();
    const [dataState, setDataState] = useState<DataSourceRequestState>(resetDataState);

    const refresh = useCallback(() => {
      setLoading(true);
      const queryStr = `${toDataSourceRequestString(dataState)}`;
      fetchApi<PositionsType>({ url:`Positions/${tripId}?${queryStr}`, method: 'POST' })
        .then(data => {
          setLoading(false);
          setPositions(data);
        })
        .catch(err => {
          alert(err);
          setLoading(false);
        });
    }, [tripId, dataState]);

    useEffect(() => {
      refresh();
    }, [tripId, refresh]);

    const savePosition = (newPosition: NewPositionType) => {
      return fetchApi<JsonResponse>({ url: `AddPosition/${tripId}`, payload: newPosition, method: 'POST' })
        .then((response) => {
          if (response.Success) {
            setShowNewPosition(false);
            refresh();
          }
          else
          {
            alert(response.ErrorMessage);
          }
        })
        .catch((e) => {
          // If not problem details
          if (!e?.status) alert("Unable to save position");
        });
    }

  return (<Dialog
    className="dialog-w1140"
    title="Trip Positions"
    onClose={close}
  >
    {showNewPosition && <ManualPositionPopup
      close={() => setShowNewPosition(false)}
      save={savePosition}
    />}
    {loading && <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>}
    {positions && <Grid
      {...dataState}
      size="small"
      data={positions.Data}
      total={positions.Total}
      sortable
      pageable
      onDataStateChange={(e) => setDataState(e.dataState)}
    >
      <GridToolbar>
        <Button
          themeColor="primary"
          onClick={() => setShowNewPosition(true)}
        >New Position
        </Button>
        <Button
          onClick={() => setDataState({ ...dataState })}
        >Refresh
        </Button>
      </GridToolbar>
      <Column field="DateTime" title="Date" filterable={false} filter="date" cell={DateTimeCell} />
      <Column field="City" />
      <Column field="State" />
      <Column field="Speed" filter="numeric" format="{0:n0} mph" />
      <Column field="Direction" filter="numeric" />
      <Column field="RepeatCount" title="Repeated" filter="numeric" format="{0}x" />
      <Column field="UserNumber" title="User" />
      <Column field="Event" filterable={false} cell={(e) => <td>{positionEventName(e.dataItem.Event)}</td>} />
    </Grid>}
  </Dialog>);
}

export default TripPositions;