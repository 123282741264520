import { Button, Checkbox, FormControlLabel, FormGroup, IconButton, Link, Snackbar, SnackbarCloseReason } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import { fetchApi } from 'core/fetchApi';
import { Input } from '@progress/kendo-react-inputs';
import { Hint, Label } from '@progress/kendo-react-labels';
import { useLoadBoardDispatch } from 'contexts/loadboard/context';

export type SettingsResponse = {
  EmailMonthlyReport: boolean;
  VehicleSearchUrl: string;
  WebhookUrl: string;
}

const Settings = () => {
  const { refresh } = useLoadBoardDispatch();
  const [snackbar, setSnackbar] = useState('');
  const data = useLoaderData() as SettingsResponse;
  const [emailMonthlyReport, setEmailMonthlyReport] = useState(data.EmailMonthlyReport);
  const [vehicleSearchUrl, setVehicleSearchUrl] = useState(data.VehicleSearchUrl);
  const [webhookUrl, setWebhookUrl] = useState(data.WebhookUrl);

  const handleCloseSnackbar = (_: Event | React.SyntheticEvent, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar('');
  };

  const _save = () => {
    fetchApi<{ Success: boolean, ErrorMessage: string }>({
      url: 'Settings',
      method: 'POST',
      payload: {
        EmailMonthlyReport: emailMonthlyReport,
        VehicleSearchUrl: vehicleSearchUrl,
        WebhookUrl: webhookUrl,
      }
    }).then((response) => {
      if (!response.Success) {
        alert(response.ErrorMessage);
      } else {
        setSnackbar('Successfully Saved!');
        refresh(); // To apply the new vehicleSearchUrl
      }
    })
    .catch(() => {
      alert('Unknown error saving!');
    });
  }
  
  return <>
    <FormGroup>
      <FormControlLabel
        control={<Checkbox
          checked={emailMonthlyReport}
          onChange={(e) => setEmailMonthlyReport(e.target.checked)}
        />}
        label="Email Monthly Performance Report"
      />
    </FormGroup>
    <br />
    <FormGroup>
      <Label>Vehicle Search URL</Label>
      <Input
        type="url"
        maxLength={200}
        value={vehicleSearchUrl}
        onChange={(e) => setVehicleSearchUrl(e.value)}
        placeholder='https://yourtms.com/vehicle/search?minpayload={weight_lbs}&city={pickup_city}&state={pickup_state}&pickupdate={pickup_date:MM/dd/yyyy}'
      />
      <Hint>This URL is used to provide a quick vehicle search link. Allowed paramaters are: &#123;pickup_city&#125;, &#123;pickup_state&#125;, &#123;pickup_date&#125;, and &#123;weight_lbs&#125;.</Hint>
    </FormGroup>
    <br />
    <FormGroup>
      <Label>API Webhook URL</Label>
      <Input
        type="url"
        maxLength={200}
        value={webhookUrl}
        onChange={(e) => setWebhookUrl(e.value)}
        placeholder='https://yourcompany.com/quote/webhook'
      />
      <Hint>This URL is used to integrate with your TMS or other systems. View documentation for bidding here:&nbsp;<Link href="https://app.load1.com/swagger/index.html?urls.primaryName=RequestForQuote" target="_blank" rel="noreferrer">https://app.load1.com/swagger/index.html?urls.primaryName=RequestForQuote</Link></Hint>
    </FormGroup>
    <br />
    <Button variant="contained" color="primary" onClick={_save}>Save</Button>
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={!!snackbar}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
      message={snackbar}
      action={
        <IconButton size="small" aria-label="close" color="inherit" onClick={(e) => handleCloseSnackbar(e, 'timeout')}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  </>;
}

export default Settings;