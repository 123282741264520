import { Navigate } from 'react-router-dom';
import { useAuthState } from 'contexts/auth/context';

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { token } = useAuthState();

  if (!token) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;